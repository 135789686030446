import { Options, Vue } from 'vue-class-component'
import { APIURL } from '@/components/Utility/APIBase'
import MediasDataService from '@/services/MediasDataService'
import { LandsHolds } from '@/services/LandsHoldsService'
import CommonFunctions from '@/components/Utility/Common'
import LoadingButton from '@/components/Frontend/LoadingButton/index.vue'

type landsHoldPlayerDataType = { name: string; playerType: string; category: string; club: string; startDate: string; pile: string, imageUrl: string }

@Options({
  props: {
    msg: String
  },
  components: {
    LoadingButton
  }
})

export default class AdminLandsHold extends Vue {
  $Message: any
  msg!: string
  APIURL = APIURL
  tabValue = 'Vis og rediger landshold'
  error: any = null
  todaysDate: Date = new Date()
  landsHoldPlayer: landsHoldPlayerDataType = { name: '', playerType: '', category: '', club: '', pile: '', startDate: `${this.todaysDate.toISOString().substring(0, 10)}`, imageUrl: '' }
  private medias: any[] = []
  playerTypeOptions: string[] = ['herre', 'dame', 'junior', 'paradart']
  playerCategoryOptions: string[] = ['brutto', 'landshold', 'bobler']
  private imageModal = false
  selectedLandsHoldImage = ''
  selectedFile: any = null
  lastCreatedMediaId = 0
  loadingStatus = false
  private landsHoldsData: any[] = []
  private confirmationModal = false
  private selectedLandsHoldPlayer = null
  private editLandsholdModal = false
  landsHoldPlayerEdit: landsHoldPlayerDataType = { name: '', playerType: '', category: '', club: '', pile: '', startDate: `${this.todaysDate.toISOString().substring(0, 10)}`, imageUrl: '' }
  landsHoldPlayerEditPayload = {}

  public retrieveMedias () : void {
    MediasDataService.getAll()
      .then((response: any) => {
        this.medias = response.data
        console.log(response.data)
      })
      .catch((err: any) => {
        this.error = err
      })
  }

  public getOnlyImages () : any {
    return this.medias
      .filter((element) => { return (element.mime === 'image/jpeg' || element.mime === 'image/png' || element.mime === 'image/webp') })
  }

  public useSelectedImage () : void {
    if (this.selectedLandsHoldImage !== '' && (this as any).selectedLandsHoldImage.url) {
      this.landsHoldPlayer.imageUrl = (this as any).selectedLandsHoldImage.url
      this.imageModal = false
    }
  }

  public onFileSelected (event: any) : void {
    this.selectedFile = event.target.files[0]
  }

  public onUpload () : void {
    if (this.selectedFile !== null && (this.selectedFile.type === 'image/jpeg' || this.selectedFile.type === 'image/png' || this.selectedFile.type === 'image/webp')) {
      const fd: FormData = new FormData()
      fd.append('files', this.selectedFile)
      MediasDataService.upload(fd)
        .then((response) => {
          this.lastCreatedMediaId = response.data.id
          this.$Message.success({ text: 'Billedet succesfuldt uploadet' })
          this.retrieveMedias()
        })
        .catch((err) => {
          this.error = err
          this.$Message.danger({ text: 'Ups et eller andet gik galt' })
        })
    } else {
      this.$Message.danger({ text: 'Den valgte fil kan desvære ikke uploades' })
    }
  }

  public cancelImage () : void {
    this.selectedLandsHoldImage = ''
  }

  public async AddPlayerInLandsHold () : Promise<void> {
    this.loadingStatus = true
    const createLandsHoldPlayerData = {
      landshold_navn: this.landsHoldPlayer.name,
      landshold_landshold: this.landsHoldPlayer.playerType,
      landshold_udtagelse: this.landsHoldPlayer.category,
      landshold_klub: this.landsHoldPlayer.club,
      landshold_startede: this.landsHoldPlayer.startDate,
      landshold_pile: this.landsHoldPlayer.pile,
      landshold_billede_url: this.landsHoldPlayer.imageUrl
    }

    await LandsHolds.LandsHoldsDataService.create(createLandsHoldPlayerData)
      .then((response) => {
        this.$Message.success({ text: 'Player Added to the national team.' })
        this.selectedLandsHoldImage = ''
        this.landsHoldPlayer = { name: '', playerType: '', category: '', club: '', pile: '', startDate: `${this.todaysDate.toISOString().substring(0, 10)}`, imageUrl: '' }
        this.retrieveLandsHolds()
      })
      .catch((error) => {
        console.log('ERROR', error)
        this.$Message.danger({ text: 'Not Added' })
      })
      .finally(() => {
        this.loadingStatus = false
      })
  }

  get isDissable () {
    let isEmpty = false
    for (const key in this.landsHoldPlayer) {
      if ((this as any).landsHoldPlayer[key] === '') {
        isEmpty = true
        break
      }
    }
    return isEmpty
  }

  // View and edit national teams part

  public async retrieveLandsHolds () : Promise<void> {
    await LandsHolds.LandsHoldsDataService.getLandsHolds()
      .then((response) => {
        this.landsHoldsData = response.data
      })
      .catch((error) => {
        console.log('ERROR', error)
      })
  }

  public setLandsHoldPlayer (landsholdPlayer: any) : void {
    this.selectedLandsHoldPlayer = landsholdPlayer
    if (this.selectedLandsHoldPlayer) {
      this.landsHoldPlayerEdit.name = (this as any).selectedLandsHoldPlayer?.landshold_navn
      this.landsHoldPlayerEdit.club = (this as any).selectedLandsHoldPlayer?.landshold_klub
      this.landsHoldPlayerEdit.playerType = (this as any).selectedLandsHoldPlayer?.landshold_landshold
      this.landsHoldPlayerEdit.category = (this as any).selectedLandsHoldPlayer?.landshold_udtagelse
      this.landsHoldPlayerEdit.imageUrl = (this as any).selectedLandsHoldPlayer?.landshold_billede_url ? (this as any).selectedLandsHoldPlayer.landshold_billede_url : (this as any).selectedLandsHoldPlayer.landshold_billede?.url
      this.landsHoldPlayerEdit.pile = (this as any).selectedLandsHoldPlayer?.landshold_pile
      this.landsHoldPlayerEdit.startDate = (this as any).selectedLandsHoldPlayer?.landshold_startede
    }
  }

  public async deleteSelectedLandsHoldPlayer () : Promise<void> {
    if (this.selectedLandsHoldPlayer) {
      const id = (this as any).selectedLandsHoldPlayer?.id
      await LandsHolds.LandsHoldsDataService.delete(id.toString())
        .then((response) => {
          this.$Message.success({ text: 'Player Deleted' })
          this.retrieveLandsHolds()
        })
        .catch((error) => {
          console.log('ERROR', error)
          this.$Message.danger({ text: 'Not Sleted' })
        })
        .finally(() => {
          this.confirmationModal = false
          this.selectedLandsHoldPlayer = null
        })
    }
  }

  public useSelectedImageEdit () : void {
    if (this.selectedLandsHoldImage !== '' && (this as any).selectedLandsHoldImage.url) {
      this.landsHoldPlayerEdit.imageUrl = (this as any).selectedLandsHoldImage.url
      this.imageModal = false
    }
  }

  public async updateSelectedLandsHoldPlayer () : Promise<void> {
    if (this.selectedLandsHoldPlayer) {
      if (this.landsHoldPlayerEdit.name !== (this as any).selectedLandsHoldPlayer?.landshold_navn) {
        (this as any).landsHoldPlayerEditPayload.landshold_navn = this.landsHoldPlayerEdit.name
      }
      if (this.landsHoldPlayerEdit.club !== (this as any).selectedLandsHoldPlayer?.landshold_klub) {
        (this as any).landsHoldPlayerEditPayload.landshold_klub = this.landsHoldPlayerEdit.club
      }
      if (this.landsHoldPlayerEdit.playerType !== (this as any).selectedLandsHoldPlayer?.landshold_landshold) {
        (this as any).landsHoldPlayerEditPayload.landshold_landshold = this.landsHoldPlayerEdit.playerType
      }
      if (this.landsHoldPlayerEdit.category !== (this as any).selectedLandsHoldPlayer?.landshold_udtagelse) {
        (this as any).landsHoldPlayerEditPayload.landshold_udtagelse = this.landsHoldPlayerEdit.category
      }
      if (this.landsHoldPlayerEdit.pile !== (this as any).selectedLandsHoldPlayer?.landshold_pile) {
        (this as any).landsHoldPlayerEditPayload.landshold_pile = this.landsHoldPlayerEdit.pile
      }
      if (this.landsHoldPlayerEdit.imageUrl !== ((this as any).selectedLandsHoldPlayer?.landshold_billede_url ? (this as any).selectedLandsHoldPlayer.landshold_billede_url : (this as any).selectedLandsHoldPlayer.landshold_billede?.url)) {
        (this as any).landsHoldPlayerEditPayload.landshold_billede_url = this.landsHoldPlayerEdit.imageUrl
      }
      if (this.landsHoldPlayerEdit.startDate !== (this as any).selectedLandsHoldPlayer?.landshold_startede) {
        (this as any).landsHoldPlayerEditPayload.landshold_startede = this.landsHoldPlayerEdit.startDate
      }
      const selectedId = (this as any).selectedLandsHoldPlayer.id
      await LandsHolds.LandsHoldsDataService.update(selectedId.toString(), this.landsHoldPlayerEditPayload)
        .then((response) => {
          this.$Message.success({ text: 'Player Updated' })
          this.retrieveLandsHolds()
        })
        .catch((error) => {
          console.log('ERROR', error)
          this.$Message.danger({ text: 'Not Update' })
        })
        .finally(() => {
          this.selectedLandsHoldImage = ''
          this.landsHoldPlayerEdit = { name: '', playerType: '', category: '', club: '', pile: '', startDate: `${this.todaysDate.toISOString().substring(0, 10)}`, imageUrl: '' }
          this.editLandsholdModal = false
          this.selectedLandsHoldPlayer = null
          this.landsHoldPlayerEditPayload = {}
        })
    }
  }

  get isUpdateDissable () {
    let isChanged = true
    if (this.selectedLandsHoldPlayer) {
      if (this.landsHoldPlayerEdit.name !== (this as any).selectedLandsHoldPlayer?.landshold_navn) {
        isChanged = false
      } else if (this.landsHoldPlayerEdit.club !== (this as any).selectedLandsHoldPlayer?.landshold_klub) {
        isChanged = false
      } else if (this.landsHoldPlayerEdit.playerType !== (this as any).selectedLandsHoldPlayer?.landshold_landshold) {
        isChanged = false
      } else if (this.landsHoldPlayerEdit.category !== (this as any).selectedLandsHoldPlayer?.landshold_udtagelse) {
        isChanged = false
      } else if (this.landsHoldPlayerEdit.pile !== (this as any).selectedLandsHoldPlayer?.landshold_pile) {
        isChanged = false
      } else if (this.landsHoldPlayerEdit.imageUrl !== ((this as any).selectedLandsHoldPlayer?.landshold_billede_url ? (this as any).selectedLandsHoldPlayer.landshold_billede_url : (this as any).selectedLandsHoldPlayer.landshold_billede?.url)) {
        isChanged = false
      } else if (this.landsHoldPlayerEdit.startDate !== (this as any).selectedLandsHoldPlayer?.landshold_startede) {
        isChanged = false
      } else {
        isChanged = true
      }
    }
    return isChanged
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.id < 100 && userDataObject.role !== undefined && userDataObject.role !== null && userDataObject.role.id === 5) {
        this.retrieveLandsHolds()
        this.retrieveMedias()
      } else {
        CommonFunctions.redirectLogin()
      }
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
