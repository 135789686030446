
import { Options, Vue } from 'vue-class-component'
import AdminLandsHold from '@/components/LandsHold/AdminLandsHold/index.vue'

@Options({
  components: {
    AdminLandsHold
  }
})
export default class LandsHoldView extends Vue {}
